<template>
	<div class="media-poster-parent">

		<transition
		    enter-active-class="animated zoomInUp"
		    leave-active-class="animated zoomOut"
		    mode="out-in"
		    appear
		    @enter="onEnter"
		    @leave="onLeave"
		>
			<!-- DYNAMIC COMPONENT -->
			<component
			    :is="mediaPosterComponent"
				v-if="mediaPosterPath && !mediaPosterHide"
				:author="mediaPosterAuthor"
				:dataObject="mediaPosterRequest"
				:key="mediaPosterPath"
			  	:path="mediaPosterPath"
			  	:type="mediaPosterType"
			>
				<!-- ATTRIBUTION -->
				<MediaPosterAttribution
					v-if="mediaPosterAuthor && mediaPosterAuthor !== 'System'"
					:author="mediaPosterAuthor"
					class="poster-attribution"
				/>

			</component>
		</transition>

	</div>
</template>

<script>
	// https://3dtransforms.desandro.com/box
	// https://codepen.io/pespantelis/pen/RWVZxL
	// https://codepen.io/MattyBalaam/pen/eaJto
	// https://www.thonky.com/javascript-and-css-guide/javascript-image-preload

	import { mapGetters } from 'vuex';
	import MediaPosterAlbum from './MediaPosterAlbum.vue';
	import MediaPosterAttribution from './MediaPosterAttribution.vue';
	import MediaPosterStreamer from './MediaPosterStreamer.vue';
	import MediaPosterMovie from './MediaPosterMovie.vue';

	export default {
		components: {
			MediaPosterAlbum,
			MediaPosterAttribution,
			MediaPosterMovie,
			MediaPosterStreamer
		},
		computed: {
			...mapGetters({
				getActiveTask: 'tasks/getActiveTask',
				getAllTasks: 'tasks/getAllTasks',
			}),
			mediaPosterRequest() {
				return this.getAllTasks.find(item =>
					item.group === 'mediaPoster' &&
				    item.data.value === 'Request' &&
				    !item.canceled
				);
			},
			mediaPosterAuthor() {
				return this.mediaPosterRequest && this.mediaPosterRequest.author;
			},
			mediaPosterHide() {
				return !!this.getActiveTask('mediaPoster', 'Hide');
			},
			mediaPosterPath() {
				return this.mediaPosterRequest && this.mediaPosterRequest.data.arg;
			},
			mediaPosterPause() {
				return !!this.getActiveTask('mediaPoster', 'Pause');
			},
			mediaPosterSkip() {
				return !!this.getActiveTask('mediaPoster', 'Skip');
			},
			mediaPosterType() {
				return this.mediaPosterRequest && this.mediaPosterRequest.data.type;
			},
			mediaPosterComponent() {
				switch(this.mediaPosterType) {
					case 'actor': return MediaPosterMovie; // CHANGE THIS IF ACTOR GETS MADE INTO NEW FILE
					case 'album': return MediaPosterAlbum;
					case 'movie': return MediaPosterMovie;
					case 'streamer': return MediaPosterStreamer;
					default: return false;
				}
			}
		},
		watch: {

			//------------------------------------------------------------
			// WATCH: POSTER PAUSE
			//------------------------------------------------------------

			mediaPosterPause(newVal, oldVal) {
				if (this.mediaPosterRequest && newVal !== oldVal) {

					// Toggle pause.
					this.mediaPosterRequest.paused = newVal;

					// Reapply timing function.
					if (!newVal) {
						this.mediaPosterRequest.timingFunction();
					}
				}
			},

			//------------------------------------------------------------
			// WATCH: POSTER REQUEST
			//------------------------------------------------------------

			mediaPosterRequest: {
				immediate: true,
				handler(newVal, oldVal) {
					if (!!newVal && newVal !== oldVal) {

						// The first-in-line is "awake", duration is active.
						newVal.sleeping = false;

						newVal.paused = this.mediaPosterPause;

						// Don't start timing functions if paused.
						if (!this.mediaPosterPause) {

							// Now that task is "awake", trigger the inbuilt timing function to begin the disposal process.
							newVal.timingFunction();
						}
					}
				}
			},

			//------------------------------------------------------------
			// WATCH: POSTER SKIP
			//------------------------------------------------------------

			mediaPosterSkip(newVal, oldVal) {
				if (!!newVal && newVal !== oldVal) {
					if (this.mediaPosterRequest) {

						// Cancel task by ID.
						this.$store.dispatch('tasks/cancelTasksById', [this.mediaPosterRequest.id]);
					}
				}
			}
		},

		//------------------------------------------------------------
		// METHODS
		//------------------------------------------------------------

		methods: {
			onEnter() {
				this.$playSound('GUI_Swipe 01', { volume: 0.5, interrupt: true });
			},
			onLeave() {
				this.$playSound('GUI_Swipe 04', { volume: 0.3, interrupt: true });
			}
		}
	}
</script>

<style lang="scss" scoped>

	.poster-attribution {
		animation: little-jump 300ms ease 0.7s alternate 1 forwards;
		// cubic-bezier(1.0, 0.5, 0.8, 1.0);
		opacity: 0;

		@keyframes little-jump {
			from {
				opacity: 0;
				transform: scale(0.7) translateY(40px);
			} to {
				opacity: 1;
				transform: scale(1.0) translateY(0);
			}
		}
	}

	//-----------------------------------------------------------------
	// MEDIA POSTER PARENT
	//-----------------------------------------------------------------

	.media-poster-parent {
		position: absolute;
		top: rem-calc(30);
		left: 0;
		bottom: rem-calc(121); // [LOCKED] - dashboard height
		width: 100%;
		// border: 2px dashed yellow;
		pointer-events: none;
		z-index: 2; // needed?
	}
</style>